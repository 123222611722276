import React from 'react';
import heroSectionBg from '@src/videos/heroSectionBg.mp4';
import TitleDescriptionLayout from '@components/v5/layouts/TitleDescriptionLayout/index';

/**
 * HeroSection component for displaying hero section with video background and text overlay.
 * @param {object} data - Data object containing header and sub properties.
 * @param {string} data.header - Header text to be displayed.
 * @param {string} data.sub - Subheader text to be displayed.
 * @returns {JSX.Element} - Rendered HeroSection component.
 */

export default function HeroSection({ data }) {
  const { header, sub } = data;
  return (
    <>
      {/* Container for hero section with minimum height */}
      <div className="relative min-h-[600px]">
        {/* Video background */}
        <video
          playsInline
          src={heroSectionBg}
          className="absolute z-0 mx-auto h-full object-cover pt-[109px]"
          autoPlay
          muted
          loop
          style={{ width: '100%', filter: 'brightness(0.7)' }}
        />

        <div
          style={{
            background:
              'linear-gradient(0deg, #181818 15.71%, rgba(74, 74, 74, 0) 79.15%)',
          }}
        />

        {/* Text content overlay on the video */}
        <div className="absolute bottom-16 z-10 w-full font-manrope md:bottom-10">
          <TitleDescriptionLayout
            header={header}
            sub={sub}
            containerStyle="container flex flex-col gap-5 md:gap-9"
            headerStyle="text-[28px] font-extrabold leading-[38px] text-v5-neutral-100 md:text-[44px] md:leading-normal"
            subStyle="text-base font-medium leading-normal text-v5-neutral-300 md:text-[22px] max-w-[976px]"
          />
        </div>
      </div>
    </>
  );
}
