import React from 'react';
import { useFormContext } from 'react-hook-form';
import JobApplicationView from './JobApplicationsView/index';
import NoJobApplicationView from './NoJobApplicationView/index';

export default function JobApplicationDashboard({ currentOpenings }) {
  const { watch } = useFormContext();

  const { team, location } = watch();

  /*Filter the current openings based on:
     1. Opening Status === "open"
     2. Team === selected team from the dropdown
     3. Location === selected location from the dropdown
     4. Sort the openings based on the priority
   */

  const filteredCurrentOpenings = currentOpenings
    .filter((opening) => opening.jobStatus === 'Open')
    .filter(
      (opening) =>
        (team === 'All teams' || !team || opening.jobTeam === team) &&
        (location === 'All locations' ||
          !location ||
          opening.jobLocation.includes(location)),
    )
    .sort(
      (a, b) =>
        parseInt(a.jobPriority.substring(1), 10) -
        parseInt(b.jobPriority.substring(1), 10),
    );

  return filteredCurrentOpenings.length > 0 ? (
    <JobApplicationView filteredCurrentOpenings={filteredCurrentOpenings} />
  ) : (
    <NoJobApplicationView />
  );
}
