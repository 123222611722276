import React from 'react';
import TitleDescriptionLayout from '@components/v5/layouts/TitleDescriptionLayout/index';

/**
 * A functional component that renders a text note using the TitleDescriptionLayout component.
 * @param {Object} props - The props object.
 * @param {Object} props.textNote - The textNote object containing header and sub properties.
 * @param {string} props.textNote.header - The header content for the text note.
 * @param {string} props.textNote.sub - The subheader content for the text note.
 * @returns {JSX.Element} The rendered TextNote component.
 */

export default function TextNote({ textNote }) {
  const { header, sub } = textNote;
  return (
    <TitleDescriptionLayout
      header={header}
      sub={sub}
      containerStyle="flex flex-col gap-7 font-manrope md:w-1/2 w-full md:text-left text-center items-center md:items-stretch"
      headerStyle="md:text-[32px] md:leading-normal text-[20px] leading-[30px]"
      subStyle="md:text-base font-medium text-v5-neutral-500 max-w-[368px] text-[12px]"
    />
  );
}
