import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { cn } from '@/lib/utils';

/**
 * TimelineCard component displays a single timeline event card.
 * @param {Object} props - The props object.
 * @param {Object} props.cardData - The data for the timeline event card.
 * @param {Function} props.onClickNext - Function to handle click on next button.
 * @param {Function} props.onClickPrev - Function to handle click on previous button.
 * @param {boolean} props.isFirstSlide - Indicates if the current card is the first one.
 * @param {boolean} props.isLastSlide - Indicates if the current card is the last one.
 * @returns {JSX.Element} The rendered TimelineCard component.
 */

export default function TimelineCard({
  cardData,
  onClickNext,
  onClickPrev,
  isFirstSlide,
  isLastSlide,
}) {
  // Query to get the images for the timeline card:
  const data = useStaticQuery(graphql`
    query {
      crioJourneyImages: allFile(
        filter: { relativePath: { regex: "images/about-us-v2/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const crioJourneyNode = data?.crioJourneyImages?.nodes.find(
    (node) => node.name === cardData.cardImg,
  );
  const tickIconNode = data?.crioJourneyImages?.nodes.find(
    (node) => node.name === 'tick-icon',
  );

  const image = getImage(crioJourneyNode?.childImageSharp);
  const tickIcon = getImage(tickIconNode?.childImageSharp);

  const {
    event: { header, points },
  } = cardData;

  return (
    // Container for the timeline card
    <div className="relative flex w-full items-center justify-center gap-3">
      {/* Previous button */}
      <div
        className={cn(
          'hidden cursor-pointer items-center justify-center rounded-full bg-[#0093781A] text-[#7A8B87] md:flex md:h-[60px] md:w-[60px]',
          isFirstSlide && 'text-[#7A8B8733]',
        )}
        onClick={onClickPrev}
      >
        <FontAwesomeIcon icon={faAngleLeft} className="text-[30px]" />
      </div>

      {/* Main content of the card */}
      <div className="w-[90%] rounded-[20px] bg-white font-manrope md:h-[360px]">
        <div className="flex h-full flex-col items-center gap-10 px-6 py-5 md:flex-row md:items-start md:gap-16 lg:px-32 lg:py-12">
          {/* Header text (visible in mobile view) */}
          <div className="leading-1 block text-center  text-xs font-bold text-[#7A8B87] md:hidden md:max-w-[140px]">
            {header}
          </div>

          {/* Image */}
          <div className="h-full w-[96px] flex-shrink-0 md:w-[200px]">
            <GatsbyImage
              image={image}
              height={200}
              width={200}
              layout="constrained"
            />
          </div>

          {/* Main content */}
          <div className="flex h-full w-full flex-col items-center gap-12 md:items-stretch">
            {/* Header text (visible in desktop view) */}
            <div className="leading-1 hidden font-bold text-v5-neutral-500 md:block md:text-[22px]">
              {header}
            </div>

            {/* Points */}
            <div className="flex h-full flex-col gap-2">
              {/* Render each point */}
              {points.map((point, idx) => (
                <div key={idx} className="flex gap-3 md:items-start md:gap-5">
                  {/* Tick icon */}
                  <span className="h-[8px] w-[8px] flex-shrink-0 md:h-[15px] md:w-[15px]">
                    <GatsbyImage
                      image={tickIcon}
                      width={15}
                      height={15}
                      layout="fixed"
                      className="h-full w-full"
                    />
                  </span>
                  {/* Point text */}
                  <span className="flex-grow font-rubik text-xs font-normal text-v5-neutral-500 md:text-base">
                    {point}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Next button */}
      <div
        className={cn(
          'hidden cursor-pointer items-center justify-center rounded-full bg-[#0093781A] text-[#7A8B87] md:flex md:h-[60px] md:w-[60px]',
          isLastSlide && 'text-[#7A8B8733]',
        )}
        onClick={onClickNext}
      >
        <FontAwesomeIcon icon={faAngleRight} className="text-[30px]" />
      </div>
    </div>
  );
}
