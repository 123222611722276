import Layout from '@components/v5/layouts/Layout/index';
import React from 'react';
import TestimonialsHeader from './TestimonialsHeader/index';
import TestimonialsSection from './TestimonialsSection/index';

export default function Testimonials({ header, sub, testimonials, id }) {
  return (
    <Layout
      className="container flex flex-col items-center gap-6 py-12 md:gap-12 md:py-20"
      id={id}
    >
      <TestimonialsHeader header={header} sub={sub} />
      <TestimonialsSection testimonials={testimonials} />
    </Layout>
  );
}
