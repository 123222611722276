import React, { useEffect } from 'react';

import '../../../../../../images/about-us-v2/team_icon.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';

export default function JobApplicationCard({ jobApplicationData }) {
  const { jobRole, jobTeam, jobLocation, jobApplicationLink } =
    jobApplicationData;

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    // Load the Tally.so script
    loadScript('https://tally.so/widgets/embed.js')
      .then(() => {
        console.log('Tally.so script loaded successfully');
      })
      .catch(() => {
        console.error('Failed to load the Tally.so script');
      });
  }, []);

  return (
    <div>
      {/* Header with bg color(no text) */}
      <div className="h-[27px] rounded-t-[10px] bg-[#0093781A]"></div>

      {/* Card Body */}
      <div className="relative h-[190px] rounded-b-[10px] bg-white font-rubik md:h-[315px]">
        <div className="h-full">
          {/* Job Title */}
          <div className="max-w-[150px] px-3 pt-3 pb-1 text-xs font-semibold text-v5-neutral-500 md:max-w-[260px] md:px-6 md:pb-4 md:pt-6 md:text-lg">
            {jobRole}
          </div>
          {/* Line separation */}
          <div className="absolute h-[1px] w-full bg-[#0093781A]"></div>

          {/* Job Details: Team and Location */}
          <div className="flex flex-col gap-3 py-4 px-3 md:gap-6 md:px-6 md:py-5">
            <div className="flex items-center gap-[10px]">
              <FontAwesomeIcon
                icon={faUsers}
                className="w-[20px] text-v5-green-300"
              />
              <div className="text-[10px] leading-[10px] text-v5-neutral-500 md:text-base md:leading-normal">
                <span className="font-semibold">Team: </span>
                <span className="font-normal">{jobTeam}</span>
              </div>
            </div>
            <div className="flex items-center gap-[10px]">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="w-[20px] text-v5-green-300"
              />
              <div className="text-[10px] leading-[10px] text-v5-neutral-500 md:text-base md:leading-normal">
                <span className="font-semibold">Location: </span>
                <span className="font-normal">{jobLocation}</span>
              </div>
            </div>
          </div>

          {/* Job Application Link */}
          <div className="absolute bottom-8 cursor-pointer px-6 text-xs font-bold text-v5-green-300 md:text-lg">
            <a
              href={`#tally-open=${jobApplicationLink}&tally-emoji-text=👋&tally-emoji-animation=wave`}
            >
              Apply Now {'>'}
            </a>
            <div className="h-[2px] w-full bg-v5-green-300"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
