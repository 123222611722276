import React from 'react';
import TitleDescriptionLayout from '@components/v5/layouts/TitleDescriptionLayout/index';

export default function CrioJourneyHeader({ header, sub }) {
  return (
    <div className="flex flex-col items-center">
      <TitleDescriptionLayout
        header={header}
        sub={sub}
        containerStyle="container flex flex-col gap-7 items-center"
        headerStyle="md:text-[32px] md:leading-normal text-center text-xl"
        subStyle="text-center text-v5-neutral-500 md:text-base text-[12px] leading-[20px] md:leading-normal"
      />
    </div>
  );
}
