import React from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Controller, useFormContext } from 'react-hook-form';

export default function JobApplicationFilters({ filters }) {
  const { control } = useFormContext();

  return (
    <div className="mx-auto flex w-full flex-col items-center justify-center gap-3 md:flex-row">
      {filters.map((filter) => (
        <Controller
          control={control}
          name={filter.name}
          render={({ field }) => (
            <Select
              className="border-red-200 placeholder:text-red-300"
              onValueChange={field.onChange}
              {...field}
            >
              <SelectTrigger
                className={`h-[50px] max-w-[300px] border-[1px] border-solid border-v5-neutral-300 bg-white font-rubik text-[16px] font-normal  placeholder:text-v5-neutral-300 focus:outline-none md:text-[18px] ${
                  field.value ? 'text-v5-neutral-500' : 'text-v5-neutral-300'
                }`}
              >
                <SelectValue placeholder={filter.placeholder} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {filter.dropdownOptions.map((option) => (
                    <SelectItem value={option.value}>{option.label}</SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          )}
        />
      ))}
    </div>
  );
}
