import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import useResizer from '@components/extra/useResizer';

export default function TestimonialsCard({ testimonial }) {
  const {
    employeeImage,
    employeeName,
    employeeDesignation,
    employeeTestimonial,
  } = testimonial;
  const isScreenLessThan1200 = useResizer(1200);
  const [isReadMoreEnabled, setIsReadMoreEnabled] = React.useState(false);
  const data = useStaticQuery(graphql`
    query {
      TestimonialImages: allFile(
        filter: {
          relativePath: { regex: "images/strapi/crio-employee-testimonials/" }
        }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}

              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const testimonialImageNodes = data?.TestimonialImages?.nodes;

  const testimonialImage = testimonialImageNodes.find((node) =>
    node.name.includes(employeeImage),
  );

  const testimonialGatsbyImage = getImage(testimonialImage?.childImageSharp);
  return (
    <div
      className="hidden grid-cols-[1fr,1fr] gap-14 rounded-[20px] border border-[#E6F4F2] bg-white py-6 px-5 md:grid"
      style={{
        boxShadow: isScreenLessThan1200
          ? 'none'
          : '10px 10px 10px 0px #0000000D',
      }}
    >
      {/* Employee Image */}
      <div className="max-h-[310px] overflow-hidden rounded-[20px]">
        <GatsbyImage
          image={testimonialGatsbyImage}
          className="h-full w-full object-cover"
          alt={employeeName}
        />
      </div>

      <div className="h-[310px] overflow-scroll py-3 font-manrope">
        {/* Employee Details */}
        <div className="mb-2 font-light text-v5-neutral-500 md:text-[22px] md:leading-[32px]">
          Meet <strong className="font-extrabold">{employeeName}</strong>
        </div>
        <div className="mb-7 font-rubik font-semibold text-v5-neutral-400 md:text-base md:leading-none">
          {employeeDesignation}
        </div>
        <div className="mb-3 h-1 w-full bg-[#DAF1EA]" />

        {/*  Employee Testimonial */}
        <div className=" font-normal text-v5-neutral-400 md:text-base">
          {employeeTestimonial.length > 200 ? (
            <>
              {isReadMoreEnabled ? (
                employeeTestimonial
              ) : (
                <>
                  {employeeTestimonial.substring(0, 200)}...{' '}
                  <span
                    className="cursor-pointer text-[14px] font-semibold leading-[24px] text-v5-green-300 underline"
                    onClick={() => setIsReadMoreEnabled(true)}
                  >
                    read more
                  </span>
                </>
              )}
            </>
          ) : (
            employeeTestimonial
          )}
        </div>
      </div>
    </div>
  );
}
