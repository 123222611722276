import Layout from '@components/v5/layouts/Layout/index';
import React from 'react';
import WorkWithUsSectionHeader from './WorkWithUsSectionHeader/index';
import JobApplicationDetails from './JobApplicationDetails/index';

export default function WorkWithUsSection({ data }) {
  const { header, sub, filters } = data;
  return (
    <div className="bg-v5-neutral-200 py-12 md:py-24" id="workwithus">
      <Layout className="container flex flex-col gap-14">
        <WorkWithUsSectionHeader header={header} sub={sub} />
        <JobApplicationDetails filters={filters} />
      </Layout>
    </div>
  );
}
