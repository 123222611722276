import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import useResizer from '@components/extra/useResizer';

export default function TestimonialsCardMobile({ testimonial }) {
  const {
    employeeImage,
    employeeName,
    employeeDesignation,
    employeeTestimonial,
  } = testimonial;
  const isScreenLessThan1200 = useResizer(1200);
  const [isReadMoreEnabled, setIsReadMoreEnabled] = React.useState(false);
  const data = useStaticQuery(graphql`
    query {
      TestimonialImages: allFile(
        filter: {
          relativePath: { regex: "images/strapi/crio-employee-testimonials/" }
        }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}

              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const testimonialImageNodes = data?.TestimonialImages?.nodes;

  const testimonialImage = testimonialImageNodes.find((node) =>
    node.name.includes(employeeImage),
  );

  const testimonialGatsbyImage = getImage(testimonialImage?.childImageSharp);
  return (
    <div
      className="flex h-[190px] flex-col gap-2 rounded-[6px] border border-[#E6F4F2] bg-v5-neutral-200 py-3 px-4 md:hidden"
      style={{
        boxShadow: isScreenLessThan1200
          ? 'none'
          : '10px 10px 10px 0px #0000000D',
      }}
    >
      {/* Employee Image */}
      <div className="grid grid-cols-[56px,1fr] gap-x-5">
        <div className="max-h-[56px] overflow-hidden rounded-[4px]">
          <GatsbyImage
            image={testimonialGatsbyImage}
            className="h-full w-full object-cover"
            height={50}
            width={50}
            layout="constrained"
          />
        </div>

        <div className="relative w-full font-manrope ">
          {/* Employee Details */}
          <div className="mb-4 text-[10px] font-light leading-[10px] text-v5-neutral-500 md:text-[22px] md:leading-[32px]">
            Meet <strong className="font-extrabold">{employeeName}</strong>
          </div>
          <div className="font-rubik text-[8px] font-semibold leading-none text-v5-neutral-400 md:text-base md:leading-none">
            {employeeDesignation}
          </div>
          <div className="absolute bottom-0 h-[1px] w-full bg-[#DAF1EA]" />
        </div>
      </div>

      {/*  Employee Testimonial */}
      <div className="overflow-scroll text-[10px] font-normal leading-[15px] text-v5-neutral-400 md:text-base">
        {employeeTestimonial.length > 170 ? (
          <>
            {isReadMoreEnabled ? (
              employeeTestimonial
            ) : (
              <>
                {employeeTestimonial.substring(0, 170)}...{' '}
                <span
                  className="cursor-pointer text-[10px] font-semibold leading-[24px] text-v5-green-300 underline"
                  onClick={() => setIsReadMoreEnabled(true)}
                >
                  read more
                </span>
              </>
            )}
          </>
        ) : (
          employeeTestimonial
        )}
      </div>
    </div>
  );
}
