import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceFrown } from '@fortawesome/pro-light-svg-icons';

export default function NoJobApplicationView() {
  const containerStyle = {
    backgroundImage:
      'url(\'data:image/svg+xml,%3Csvg width="100%25" height="100%25" xmlns="http://www.w3.org/2000/svg"%3E%3Crect width="100%25" height="100%25" fill="none" rx="10" ry="10" stroke="%23B9CFCAFF" stroke-width="6" stroke-dasharray="6%2c14" stroke-dashoffset="0" stroke-linecap="square"/%3E%3C/svg%3E\')',
    borderRadius: '10px',
  };

  return (
    <div className="md:px-32">
      <div
        style={containerStyle}
        className="flex flex-col items-center justify-center gap-5 p-8 py-10 px-8 text-v5-neutral-300"
      >
        <div>
          <FontAwesomeIcon icon={faFaceFrown} className="text-[58px]" />
        </div>
        <div className="text-center">
          Sorry, we don't have any job openings at the moment. We encourage you
          to stay connected with us by following our social media channels and
          checking our careers page regularly for updates on new openings
        </div>
      </div>
    </div>
  );
}
