import React from 'react';
import TextNote from './TextNote/index';
import VideoNote from './VideoNote/index';
import Layout from '@components/v5/layouts/Layout/index';

/**
 * A functional component that renders a note from leadership, which includes
 * a text note and a video note.
 * @param {Object} props - The props object.
 * @param {Object} props.data - The data object containing textNote and videoNote.
 * @param {string} props.data.textNote - The text content for the text note.
 * @param {string} props.data.videoNote - The URL for the video note.
 * @returns {JSX.Element} The rendered NoteFromLeadership component.
 */

export default function NoteFromLeadership({ data }) {
  const { textNote, videoNote } = data;
  return (
    <Layout className="container relative z-[999] flex flex-col items-center gap-7 bg-white py-12 text-center md:flex-row md:items-stretch md:gap-2 md:py-24 md:text-left">
      <TextNote textNote={textNote} />
      <VideoNote videoUrl={videoNote} />
    </Layout>
  );
}
