import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { Navigation } from 'swiper';
import TimelineCard from './TimelineCard/index';
import useResizer from '@components/extra/useResizer';
import { cn } from '@/lib/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { useLocation } from '@reach/router';

/**
 * A functional component that renders a horizontal timeline with clickable events.
 * Each event shows a corresponding card with more details.
 * @param {Object} props - The props object.
 * @param {Array} props.timeline - An array of timeline events.
 * @returns {JSX.Element} The rendered HorizontalTimeline component.
 */

export default function HorizontalTimeline({ timeline }) {
  // State to keep track of the active timeline event:
  const [activeTimelineEvent, setActiveTimelineEvent] = useState(0);

  // State to track till which timeline events users are going to before unload event:
  const [maxTimelineEvent, setMaxTimelineEvent] = useState(0);

  // Ref for the Swiper component to control the slide:
  const swiperRef = useRef(null);
  const location = useLocation();
  const isMobile = useResizer();

  // Effect to slide to the active timeline event:
  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(
        activeTimelineEvent - (isMobile ? 2 : 4),
      );
    }
  }, [activeTimelineEvent]);

  // Effect to add a beforeunload event listener to track the maximum value
  useEffect(() => {
    const handleBeforeUnload = () => {
      GTM.track(gtmEvents.TIMELINE_MAX_EVENT, {
        pathname: location.pathname,
        maxEvent: maxTimelineEvent,
        maxEventName: timeline[maxTimelineEvent]?.event?.header,
        maxEventDate: timeline[maxTimelineEvent]?.date,
      });
    };

    document.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      document.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [maxTimelineEvent, location.pathname]);

  useEffect(() => {
    setMaxTimelineEvent((prev) => Math.max(prev, activeTimelineEvent));
  }, [activeTimelineEvent]);

  // Function to handle the timeline event click:
  const handleClickTimeline = (idx) => {
    setActiveTimelineEvent(idx);

    GTM.track(gtmEvents.TIMELINE_EVENT_CLICK, {
      pathname: location.pathname,
    });
  };

  return (
    <div className="crio-journey swiper_container relative flex w-full flex-col items-center justify-center">
      <Swiper
        ref={swiperRef}
        slidesPerView={isMobile ? 3 : 5}
        modules={[Navigation]}
        className=""
        initialSlide={activeTimelineEvent}
        allowTouchMove={isMobile ? true : false}
      >
        {/* top-[43px] is used to align the arrow button in mobile view with the center of timeline axis */}
        <div className="absolute left-0 top-[43px] z-20  flex h-10 w-12 justify-start bg-v5-neutral-200 md:top-[50px] md:hidden">
          <FontAwesomeIcon
            icon={faAngleLeft}
            className="text-[20px] text-[#7A8B87]"
          />
        </div>
        <div class="swiper-wrapper timeline cursor-pointer">
          {timeline.map(({ id, date, event }, idx) => (
            // SwiperSlide for each timeline event:
            <SwiperSlide key={id} onClick={() => handleClickTimeline(idx)}>
              {/* Header of timeline */}
              <div
                class={cn(
                  'timestamp',
                  activeTimelineEvent === idx && 'timeline-active',
                )}
              >
                <span class="date">{date}</span>
              </div>

              {/* Footer of timeline */}
              <div
                class={cn(
                  'status relative',
                  activeTimelineEvent === idx && 'status-active',
                  idx === 0 && 'status-first',
                  idx === timeline.length - 1 && 'status-last',
                )}
              >
                <span>{event?.header}</span>
              </div>
            </SwiperSlide>
          ))}
        </div>
        {/* top-[43px] is used to align the arrow button in mobile view with the center of timeline axis */}
        <div className="absolute right-0 top-[43px] z-20  flex h-10 w-12 justify-end bg-v5-neutral-200 md:top-[50px] md:hidden">
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-[20px] text-[#7A8B87]"
          />
        </div>
      </Swiper>

      {/* TimelineCard */}
      <TimelineCard
        cardData={timeline[activeTimelineEvent]}
        onClickNext={() => {
          setActiveTimelineEvent((prev) =>
            prev === timeline.length - 1 ? prev : prev + 1,
          );
          GTM.track(gtmEvents.TIMELINE_NAVIGATION_BTN_CLICK, {
            pathname: location.pathname,
          });
        }}
        isLastSlide={activeTimelineEvent === timeline.length - 1}
        isFirstSlide={activeTimelineEvent === 0}
        onClickPrev={() => {
          setActiveTimelineEvent((prev) => (prev === 0 ? prev : prev - 1));
          GTM.track(gtmEvents.TIMELINE_NAVIGATION_BTN_CLICK, {
            pathname: location.pathname,
          });
        }}
      />
    </div>
  );
}
