import React from 'react';
import JobApplicationFilters from './JobApplicationFilters/index';
import JobApplicationDashboard from './JobApplicationDashboard/index';
import { useForm, FormProvider } from 'react-hook-form';
import db from '@src/data/db.json';

export default function JobApplicationDetails({ filters }) {
  const hookForm = useForm();

  const sanitizeJobApplications = () => {
    const { crio_job_applications: crioJobApplications } =
      db?.strapi_metadata || {};

    return crioJobApplications.map(
      ({
        job_role,
        priority,
        location,
        application_link,
        status,
        team,
        id,
      }) => ({
        jobRole: job_role,
        jobPriority: priority,
        jobTeam: team,
        jobLocation: location,
        jobApplicationLink: application_link,
        jobStatus: status,
        id,
      }),
    );
  };

  const currentOpenings = sanitizeJobApplications();

  return (
    <div className="flex flex-col gap-14">
      <FormProvider {...hookForm}>
        <JobApplicationFilters filters={filters} />
        <JobApplicationDashboard currentOpenings={currentOpenings} />
      </FormProvider>
    </div>
  );
}
