import TitleDescriptionLayout from '@components/v5/layouts/TitleDescriptionLayout/index';
import React from 'react';

export default function WorkWithUsSectionHeader({ header, sub }) {
  return (
    <TitleDescriptionLayout
      header={header}
      sub={sub}
      containerStyle="container flex flex-col gap-7 items-center font-manrope"
      headerStyle="md:text-[32px] md:leading-normal font-extrabold text-center text-[20px] leading-[30px]"
      subStyle="md:text-base text-v5-neutral-500 text-[12px] leading-[20px] max-w-[1020px] text-center"
    />
  );
}
