import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Pagination, Navigation } from 'swiper';
import './styles.css';
import JobApplicationCard from '../JobApplicationCard/index';
import useResizer from '@components/extra/useResizer';

export default function JobApplicationView({ filteredCurrentOpenings }) {
  const isMobile = useResizer();
  return (
    <div>
      <div className="job-apply-container relative flex items-center">
        <Swiper
          grabCursor={true}
          slidesPerView={'auto'}
          spaceBetween={isMobile ? 18 : 50}
          pagination={{ el: '.swiper-pagination', clickable: true }}
          navigation={{
            nextEl: '.job-apply-container .swiper-button-next',
            prevEl: '.job-apply-container .swiper-button-prev',
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
        >
          {filteredCurrentOpenings.map((jobApplicationData) => (
            <SwiperSlide
              key={jobApplicationData.id}
              className="w-[186px] md:w-[326px]"
            >
              <JobApplicationCard jobApplicationData={jobApplicationData} />
            </SwiperSlide>
          ))}

          <div className="slider-controler">
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
        <div className="swiper-button-prev slider-arrow absolute -left-[100px]"></div>
        <div className="swiper-button-next slider-arrow relative -right-[100px]"></div>
      </div>
    </div>
  );
}
