import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import VideoModal from '@components/VideoModal/index';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { useLocation } from '@reach/router';

/**
 * A functional component that renders a video note with a clickable thumbnail.
 * When clicked, it opens a video modal.
 * @param {Object} props - The props object.
 * @param {string} props.videoUrl - The URL of the video to be played in the modal.
 * @returns {JSX.Element} The rendered VideoNote component.
 */

export default function VideoNote({ videoUrl }) {
  // State for managing the modal open/close state:
  const [open, setIsOpen] = React.useState(false);
  const location = useLocation();

  // Query to get the video thumbnail image:
  const data = useStaticQuery(graphql`
    query {
      Thumbnail: allFile(
        filter: { relativePath: { regex: "images/about-us-v2/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}
              width: 650
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  // Get the video thumbnail image:
  const thumbnailNode = data?.Thumbnail?.nodes.find(
    (node) => node.name === 'leadership-video-thumbnail',
  );
  const videoThumbnail = getImage(thumbnailNode?.childImageSharp);

  // Function to handle the video thumbnail click:
  const handleVideoClick = () => {
    setIsOpen((open) => !open);

    GTM.track(gtmEvents.LEADERSHIP_VIDEO_CLICK, {
      pathname: location.pathname,
    });
  };

  return (
    <div className="cursor-pointer" onClick={handleVideoClick}>
      <GatsbyImage
        loading="eager"
        image={videoThumbnail}
        placeholder="blurred"
        className="w-full"
        layout="fullWidth"
        alt="About Us"
      />
      <VideoModal videoLink={videoUrl} open={open} setIsOpen={setIsOpen} />
    </div>
  );
}
