import Testimonials from '@components/TestimonialsV2/index';
import React from 'react';
import db from '@src/data/db.json';

export default function EmployeeTestimonial({ data }) {
  const { header, sub } = data;

  //testimonials data from strapi:
  const {
    strapi_metadata: { crio_employee_testimonials },
  } = db;

  //sanitize testimonial data:
  function sanitizeTestimonialData() {
    return crio_employee_testimonials.map(
      ({ employee_name, employee_designation, employee_testimonial }) => ({
        employeeName: employee_name,
        employeeDesignation: employee_designation,
        employeeImage: employee_name.toLowerCase().replace(/\s/g, '_'),
        employeeTestimonial: employee_testimonial,
      }),
    );
  }

  const testimonials = sanitizeTestimonialData();

  return (
    <Testimonials
      header={header}
      sub={sub}
      testimonials={testimonials}
      id="#testimonials"
    />
  );
}
