import Layout from '@components/v5/layouts/Layout/index';
import React from 'react';
import CrioJourneyHeader from './CrioJourneyHeader/index';
import CrioJourneyTimeline from './CrioJourneyTimeline/index';

export default function CrioJourney({ data }) {
  const { header, sub, timeline } = data;

  return (
    <div className="bg-v5-neutral-200 py-12 md:py-24">
      <Layout
        className="container flex flex-col items-center gap-12 md:gap-20"
        id="criojourney"
      >
        {/* Section 0: Crio Journey Header */}
        <CrioJourneyHeader header={header} sub={sub} />

        {/* Section 1: Crio Journey Timeline */}
        <CrioJourneyTimeline timeline={timeline} />
      </Layout>
    </div>
  );
}
